module.exports = {
    en: {
        MissedConnectionCompensationEU: {
            text: `In case of the EU, it depends on two factors - 
      flight distance and flight boundaries. See the chart:`
        }
    },
    ka: {
        MissedConnectionCompensationEU: {
            text: `gdgsdgsgs`
        }
    }
}