import ContactUsSection from "./Contact Us Section/ContactUsSection";
import FeedBackComp from "./Feedback Form/FeedBackComp";

function ContactUs() {
  return (
    <>
      <ContactUsSection/>
      <FeedBackComp />
    </>
  );
}

export default ContactUs;
